@charset "UTF-8";
/* stylelint-disable at-rule-empty-line-before */
/* simple clearfix */
/**************************
Compass Text Replacement
*/
/**************************
Inline List
*/
/**************************
Inline Block List
*/
/**************************
horizontal-list
*/
/**************************
Bullets
*/
select,
input {
  font-size: 1rem;
}

.text_frame input,
.textarea_frame textarea {
  font-size: 1rem;
}

body {
  font-size: 1rem;
  line-height: 1.5;
}
body.mm-wrapper--opened .open_btn {
  display: none;
}
body.mm-wrapper--opened .close_btn {
  display: block;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************
## Layout
*/
.root {
  overflow: hidden;
  padding-top: 0;
}

.wrapper {
  width: 100%;
  min-width: 100%;
}

.sp_container,
.mb_container,
.container {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.sp_container-nopadding,
.mb_container-nopadding,
.container-nopadding {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (min-width: 768px) {
  .ipc_container {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 767px) {
  .isp_container {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.sp_padding,
.padding {
  padding-left: 15px;
  padding-right: 15px;
}

.main_content:not(._department_index) {
  padding-bottom: 70px;
}
@media screen and (max-width: 999px) {
  .main_content:not(._department_index) {
    padding-bottom: 0;
  }
}
.main_content img {
  display: block;
}

.sub_column {
  margin-top: 40px;
}

.scroll_to_top {
  right: 0;
}

.sp_unindent {
  margin-left: -15px;
  margin-right: -15px;
}

.sp_cell + .sp_cell {
  margin-left: 1em;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Utility - Cosmetic

ユーティリティー設定。
このサイト専用というわけでもない共通設定。

<div class="_h3">.sp</div>
PCの時だけ表示される。

<div class="_h3">.sp_block</div>
SPの時だけ、ブロック要素になる。

<div class="_h3">.tel_link</div>
SPの時だけ、リンクになる。(クリックで電話するリンク)

*/
.sp_none,
.root .sp_none {
  display: none;
}

.block,
.sp_block {
  display: block;
}

.xs_block {
  display: none;
}

@media screen and (max-width: 575px) {
  .xs_none {
    display: none;
  }
  .xs_block {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .ipc_block {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .isp_block {
    display: block;
  }
  .ipc_block + .ipc_block {
    margin-left: 0.7em;
  }
}
.sp_click {
  cursor: pointer;
}

.scell {
  display: block;
  margin-right: 0;
}

.tel_link-no {
  text-decoration: none;
  pointer-events: none;
  color: inherit;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Component - Base

コンポーネント設定。
各ページでよく使い回す記述。
このサイト専用の共通設定。

*/
/**************************

## - 段組設定 2列 3列 4列

SPの時だけ、中央に等間隔で余白を作り、width: 100%; を守って列になる。
ul でなくてもいい。

**2列 .sp_column2**

	<ul class="sp_column2">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**3列 .sp_column3**

	<ul class="sp_column3">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**4列 .sp_column4**

	<ul class="sp_column4">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

*/
.sp_column2 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: -10px;
  width: calc(100% + 10px);
}
.sp_column2 > * {
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  width: 50%;
  margin-bottom: 10px;
}

.sp_column3 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: -10px;
  width: calc(100% + 10px);
}
.sp_column3 > * {
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  width: 33.33%;
  margin-bottom: 10px;
}

.sp_column4 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: -10px;
  width: calc(100% + 10px);
}
.sp_column4 > * {
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  width: 25%;
  margin-bottom: 10px;
}

.sp_column2 img,
.sp_column3 img,
.sp_column4 img {
  margin-left: auto;
  margin-right: auto;
}

.pc_column2 img,
.pc_column3 img,
.pc_column4 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  vertical-align: bottom;
}

/**************************
.pc_column
*/
.pc_column2:not(.sp_column2, .sp_column3, .sp_column4) > *:not(:last-child) {
  margin-bottom: 1em;
}

.pc_column3:not(.sp_column2, .sp_column3, .sp_column4) > *:not(:last-child) {
  margin-bottom: 1em;
}

.pc_column4:not(.sp_column2, .sp_column3, .sp_column4) > *:not(:last-child) {
  margin-bottom: 1em;
}

.pc_column2b:not(.sp_column2, .sp_column3, .sp_column4) > *:not(:last-child) {
  margin-bottom: 1em;
}

.pc_column3b:not(.sp_column2, .sp_column3, .sp_column4) > *:not(:last-child) {
  margin-bottom: 1em;
}

.pc_column4b:not(.sp_column2, .sp_column3, .sp_column4) > *:not(:last-child) {
  margin-bottom: 1em;
}

/**************************
	.table-column2
*/
.table-column2 {
  width: 100% !important;
  max-width: 100% !important;
}
.table-column2 thead,
.table-column2 tbody,
.table-column2 tfoot,
.table-column2 tr,
.table-column2 th,
.table-column2 td {
  display: block;
  box-sizing: border-box;
  float: left;
  clear: both;
}
.root .table-column2 thead,
.root .table-column2 tbody,
.root .table-column2 tfoot,
.root .table-column2 tr,
.root .table-column2 th,
.root .table-column2 td {
  width: 100%;
}
.table-column2 tr th,
.table-column2 th + td {
  border-top: 0 none;
}
.table-column2 thead th,
.table-column2 thead td,
.table-column2 tbody th,
.table-column2 tbody td {
  width: 100% !important;
}
.table-column2 thead td + td,
.table-column2 tbody td + td {
  border-top: 0 none;
}
.table.table-column2:not(.table-noborder) tr:first-child th:first-child,
.table.table-column2:not(.table-noborder) tr:first-child td:first-child {
  border-top: 1px solid #dddddd;
}

/* form */
.input_frame.node,
.input_frame.node input {
  width: 60px;
  display: inline-block;
}

.input_frame.node2,
.input_frame.node2 input {
  width: 70px;
}

form .frame .node {
  margin-right: 3px;
}

.sp_center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.sp_center .img {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  position: relative;
  top: 0;
  left: 0;
}
.footer .scroll_to_top,
.footer .scroll_to_top a,
.footer .scroll_to_top img {
  width: 50px;
  height: 50px;
  max-width: 100%;
  display: block;
}
.footer-scroll_to_top_stop .scroll_to_top {
  position: absolute;
  top: 29px;
}
.footer-scroll_to_top_stop .scroll_to_top a,
.footer-scroll_to_top_stop .scroll_to_top img {
  max-width: 100%;
  display: block;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Project

特定ページ専用、効果範囲が限定的な記述。
コンテンツ内容に密接で、汎用性がなく、機能の付け替えを想定していない。

*/
.header {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: #ffffff;
  position: fixed;
  top: 0;
  padding: 10px 15px;
  box-shadow: 0 4px 17px 0 rgba(111, 133, 155, 0.1882352941);
  z-index: 1000;
}
.header__r {
  display: none;
}
.header__b {
  display: none;
}
.header .logo {
  width: 280px;
}
@media screen and (max-width: 420px) {
  .header .logo {
    width: 230px;
  }
}
.header__tel .tel {
  display: block;
  width: 50px;
  height: 59px;
}
.header__tel .tel img {
  display: block;
  max-width: 100%;
}
.header__search_input_area {
  display: none;
}
.header__info {
  display: none;
}
.header__buttons {
  display: none;
}
.header__search {
  display: none;
}
.header .menu_area {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .menu_area img {
  width: 50px;
}

.open_btn {
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  /* stylelint-disable-next-line at-rule-empty-line-before */
  margin: 0;
  width: 44px;
  height: 44px;
  display: block;
  position: fixed;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  box-sizing: border-box;
  z-index: 2;
}
.open_btn:hover {
  text-decoration: none;
  opacity: 0.7;
  transition: 0.2s opacity;
}
.open_btn::before {
  content: "";
  display: block;
  background: url("../img/sp/menu_open.webp") 0 0 no-repeat transparent;
  background-size: 44px 44px;
  width: 44px;
  height: 44px;
}

.close_btn {
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  /* stylelint-disable-next-line at-rule-empty-line-before */
  margin: 0;
  width: 44px;
  height: 44px;
  position: fixed;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  box-sizing: border-box;
  z-index: 2;
  display: none;
}
.close_btn:hover {
  text-decoration: none;
  opacity: 0.7;
  transition: 0.2s opacity;
}
.close_btn::before {
  content: "";
  display: block;
  background: url("../img/sp/menu_close.webp") 0 0 no-repeat transparent;
  background-size: 44px 44px;
  width: 44px;
  height: 44px;
}

.gnav_sub_area {
  display: none;
}